import { useEffect, useState, useRef } from "react"
import useWindowResize from "@/hooks/useWindowResize"
import { getEventFor } from "@/components/core/FindingModelNumber/v1/analyticsEventType"
import closeWhite from "@/public/icons/close-white.svg"
import closeIcon from "@/public/icons/modal-close.svg"
import Styles from "@/components/core/Modal/Modal.module.scss"
import { setFocus, preventBodyScroll } from "@/utils/helper"
import { isIOS } from "react-device-detect"

const Modal = props => {
  const modalWindow = useRef(null)
  const [isRendered, setIsRendered] = useState(false)
  const [width, height] = useWindowResize()
  const [style, setStyle] = useState({})

  const {
    className,
    children,
    showModal,
    modalTitle,
    labelledBy,
    describedBy,
    onModalClose,
    prevActiveElem,
    hideCloseIcon,
    closeButtonAnalytics,
    analyticsCustomData,
    needAnalyticsOnContentsLink,
    dataLayer,
    StickCloseButton,
    isWhiteCloseIcon = false,
  } = props

  const waitingTImeForModalContentToLoad = 500
  const triggerModalClose = e => {
    preventBodyScroll(false)
    onModalClose(e)
  }
  const handleKeyPress = e => {
    const focusElements =
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
    const focusContent = modalWindow.current.querySelectorAll(focusElements)
    const firstElem = focusContent[0]
    const lastElem = focusContent[focusContent.length - 1]
    if (e.key === "Escape") {
      triggerModalClose()
    }
    if (e.key === "Tab") {
      if (
        e.shiftKey &&
        (document.activeElement === firstElem ||
          document.activeElement === modalWindow.current)
      ) {
        e.preventDefault()
        lastElem.focus()
      }
      if (!e.shiftKey && document.activeElement === lastElem) {
        e.preventDefault()
        firstElem.focus()
      }
    }
  }

  useEffect(() => {
    if (width < 577 || (isIOS && width < 992)) {
      setStyle({ style: { height: height, minHeight: height } })
    } else {
      setStyle({})
    }
  }, [width, height])

  useEffect(() => {
    if (showModal) {
      if (!isRendered) {
        setIsRendered(true)
      }

      window.addEventListener("keydown", handleKeyPress)
      window.prevActiveElement = prevActiveElem ?? document.activeElement
      modalWindow.current.focus()
      if (needAnalyticsOnContentsLink) {
        addAnalyticsForNavigationLinks()
      }
    } else {
      if (!document.getElementsByClassName("show-modal-popup").length) {
        window.prevActiveElement?.focus()
        setFocus(window.keyFocusCallBack)
      }
    }

    preventBodyScroll(showModal)

    return () => {
      window.removeEventListener("keydown", handleKeyPress)
      preventBodyScroll(false)
    }
  }, [showModal])

  const getNavigationLinksEventInfo = (
    link,
    linkName,
    clickInternalLinks = "true"
  ) => {
    const type =
      window.adobeDataLayer.length > 1
        ? window.adobeDataLayer[1]["page"]["category"]
        : {}
    const eventFor = getEventFor(type)
    const internalLinkType = type?.subCategory1?.includes(
      "my account:product registration"
    )
      ? `my account:navigation`
      : `${eventFor}:navigation`

    return JSON.stringify({
      clickInternalLinks,
      eventAction: `${eventFor}:modal:where do i find my product model number:${linkName}`,
      eventMsg: "n/a",
      eventName: `${eventFor}:modal:where do i find my product model number:${linkName}`,
      eventStatus: "n/a",
      eventType: "navigation",
      internalLinkName: linkName,
      internalLinkPosition: type?.subCategoryID || "contact us",
      internalLinkType,
      internalLinkURL: link,
      internalLinkZoneName: `${eventFor}:modal:where do i find my product model number`,
    })
  }

  const addAnalyticsForNavigationLinks = () => {
    setTimeout(() => {
      modalWindow.current.querySelectorAll(".description p a").forEach(link => {
        link.classList.add("gbh-data-layer")
        const textContent =
          link?.textContent ||
          (link.querySelector("u") ? link.querySelector("u").textContent : "")
        const clickInternalLinks =
          textContent?.toLowerCase() === "learn more" ? "true" : "false"
        const eventInfo = getNavigationLinksEventInfo(
          link.getAttribute("href"),
          textContent?.toLowerCase(),
          clickInternalLinks
        )
        link.setAttribute("data-gbh-data-layer", eventInfo)
      })
      modalWindow.current
        .querySelectorAll(".description h6 a")
        .forEach(link => {
          if (link.textContent === "Learn More") {
            link.classList.add("gbh-data-layer")
            const eventInfo = getNavigationLinksEventInfo(
              link.getAttribute("href"),
              link.textContent.toLowerCase()
            )
            link.setAttribute("data-gbh-data-layer", eventInfo)
          }
        })
      modalWindow.current
        .querySelectorAll(".modal-popup-body a")
        .forEach(link => {
          if (link.textContent === "Continue") {
            link.classList.add("gbh-data-layer")
            link.setAttribute("data-gbh-data-layer", dataLayer)
          }
        })
    }, waitingTImeForModalContentToLoad)
  }

  return (
    <div className={Styles.modalWrapper}>
      <div
        className={`modal-popup ${showModal ? "show-modal-popup" : ""} ${
          className ? className : ""
        }`}
      >
        <div
          className="modal-popup-content"
          role="dialog"
          aria-modal
          tabIndex="-1"
          aria-labelledby={labelledBy}
          aria-describedby={describedBy}
          ref={modalWindow}
          {...style}
        >
          {!hideCloseIcon && (
            <div
              className={`close-button-holder ${
                StickCloseButton ? "stick-close-button" : ""
              }`}
            >
              <span
                className={`close-button${
                  closeButtonAnalytics ? " gbh-data-layer" : ""
                }`}
                data-gbh-data-layer={closeButtonAnalytics}
                data-gbh-data-layer-custom={analyticsCustomData || null}
                onClick={triggerModalClose}
                tabIndex="0"
                role="button"
                aria-label={`Click here to close ${modalTitle} modal window`}
              >
                <img
                  src={isWhiteCloseIcon ? closeWhite?.src : closeIcon?.src}
                  aria-hidden={true}
                  role="presentation"
                  alt="close"
                />
              </span>
            </div>
          )}
          <div className="modal-popup-body">
            {isRendered && showModal && children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal
